<template>
  <div class="accordion">
    <slot name="header" />

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-show="isOpen || mobileOpen" class="accordion-body">
        <slot/>
      </div>
    </transition>

    <VButton
      v-if="visibleBtn"
      block="rollup"
      :text="isOpen ? 'Свернуть' : 'Развернуть'"
      direction="row-reverse"
      size="init"
      :icon="{ name: 'rollup', dir: isOpen ? '0' : '180' }"
      @click.native="toggleIsOpen"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton'
import { mapState } from 'vuex'
export default {
  components: {
    VButton
  },
  props: {
    initIsOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    visibleBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    mobileOpen: {
      type: Boolean,
      default: false
    },
    bodyStyle: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    isOpen: false
  }),

  created () {
    this.isOpen = this.initIsOpen
  },

  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    })
  },

  methods: {
    toggleIsOpen () {
      this.isOpen = !this.isOpen
    },
    start (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    end (el) {
      el.style.height = ''
    }
  }
}
</script>
