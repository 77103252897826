<template>
  <div class="table" :class="isSticky && 'table--sticky custom-scroll'" :style="styleContainer" ref="container">
    <table
        :class="isTreeTable && 'table-tree'"
        :cols="columns.length"
    >
      <thead class="table__head">
        <tr v-if="columnsBefore.length">
          <th
            v-for="(col, i) in columnsBefore"
            :key="i"
            :class="col.classes"
            :colspan="col.colspan || 1"
            :rowspan="col.rowspan || 1"
          >
            {{ col.title }}
          </th>
        </tr>

        <tr>
          <th align="center" v-if="selectable" class="th--selectable" height="50" width="20px">
            <VCheckbox v-model="allSelected" />
          </th>
          <th
            v-for="(col, idx) in cols"
            :key="idx"
            class="th--border-t"
            :colspan="col.colspan || 1"
            :rowspan="col.rowspan || 1"
          >
            <span
              v-if="col.sortable"
              class="th--sort"
              :class="{'reverse': col.sortDesc}"
              @click="sortedHandler(col)"
            >
              <span class="mr-8">{{ col.title }}</span>

              <svgicon
                icon="arrow"
                width="13"
                height="8"
              />
            </span>

            <span v-else>{{ col.title }}</span>
          </th>
          <th v-if="showActionColumn" style="min-width: 40px; width: 40px;"></th>
        </tr>
      </thead>

      <tbody v-if="isTreeTable">
        <TableTreeItem
          v-for="(item, i) in items"
          :key="i"
          :item="item"
          :columns="columns"
          :children-keys="treeChildrenKeys"
          :visible-child="isOpenTree"
        />

        <TableTotalItem
          v-if="withTotal"
          ref="total"
          :items="items"
          :columns="columns"
        />
      </tbody>

      <tbody v-else class="table__body">
        <tr
          v-for="(item, i) in items"
          :id="isChangelog ? 'item-changelog--' + item.id : ''"
          :key="i + 'tr'"
          :class="{'tr__hover' : rowActive, 'view_row' : checkViewRow(item)}"
          @click="rowClick(item)"
        >
          <td
            align="center"
            class="th--selectable"
            v-if="selectable"
          >
            <VCheckbox
              v-model="selectedBoxes[i]"
              :name="'selected_' + i"
              @click.native.stop
              @value-changed="selectBoxes"
              ref="checkboxes"
            />
          </td>
          <td
              v-for="(col, i) in columns"
              :key="i + 'td'"
          >
            <span
              v-if="!col.isLink && !col.isStatus"
              v-html="col.handlerFormat ? col.handlerFormat(item[col.name]) : item[col.name]"
            />

            <div v-if="col.isStatus" class="d-flex jc-c">
              <span :class="item[col.name].className">
                <svgicon
                  :icon="item[col.name].icon"
                  :class="item[col.name].svgClass"
                  width="22px"
                  height="22px"
                />
                {{ item[col.name].name }}
              </span>
            </div>

            <TableLinkList
              v-if="col.isLink && Array.isArray(item[col.name])"
              :list="item[col.name]"
              :router-data="col.routerData"
            />
            <TableLink
              v-if="col.isLink && !Array.isArray(item[col.name])"
              :item="item[col.name]"
              :router-data="col.routerData"
            />
          </td>
          <td v-if="showActionColumn" style="min-width: 40px;">
            <slot name="actionButton" :item="item" />
          </td>
        </tr>

        <TableTotalItem
          v-if="withTotal"
          ref="total"
          :items="items"
          :columns="columns"
        />
      </tbody>
    </table>

    <Spinner v-if="loading" />

    <slot name="placeholder" v-if="!items.length && !loading" >
      <div class="no-records-placeholder h3">
        Нет записей
      </div>
    </slot>
  </div>
</template>

<script>
import TableTreeItem from '@/components/ui/Table/TableTreeItem'
import TableLinkList from '@/components/ui/Table/TableLinkList'
import TableLink from '@/components/ui/Table/TableLink'
import TableTotalItem from '@/components/ui/Table/TableTotalItem'
import { mapState } from 'vuex'
import VCheckbox from '@/components/Form/VCheckbox.vue'

export default {
  name: 'AppTable',
  components: {
    VCheckbox,
    TableTotalItem,
    TableLinkList,
    TableTreeItem,
    TableLink
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    columnsBefore: {
      type: Array,
      required: false,
      default: () => []
    },
    columns: {
      type: Array,
      required: true
    },
    // Для преобразования columns, если head из нескольких строк
    handlerColumns: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    rowActive: {
      type: Boolean,
      default: false
    },
    withTotal: {
      type: Boolean,
      default: false
    },
    isTreeTable: {
      type: Boolean,
      default: false
    },
    treeChildrenKeys: {
      type: Object,
      required: false
    },
    isOpenTree: {
      type: Boolean,
      required: false,
      default: true
    },
    isSticky: {
      type: Boolean,
      default: false
    },
    viewItems: {
      type: Array,
      default: () => []
    },
    isChangelog: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    showActionColumn: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      mTop: 0,
      allSelected: false,
      selectedBoxes: this.selectable ? new Array(this.items.length).fill(false) : [],
      selectedRows: []
    }
  },
  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),
    cols () {
      if (!this.handlerColumns) return this.columns
      return this.handlerColumns(this.columns)
    },
    styleContainer () {
      if (this.isSticky) {
        return {
          'max-height': `calc(100vh - ${this.mTop + (this.isMobile ? 90 : 30)}px)`
        }
      }

      return {}
    }
  },

  mounted () {
    this.mTop = this.$refs.container.getBoundingClientRect().top
  },
  methods: {
    sortedHandler (col) {
      this.$emit('sorted', col)
    },

    rowClick (item) {
      if (this.isChangelog) {
        this.viewItems.push(item.id)
      }

      this.$emit('rowClick', item)
    },

    checkViewRow (item) {
      return !!this.viewItems?.includes(item.id)
    },

    selectBoxes (data) {
      const key = data?.name?.split('_').at(-1)
      if (key) {
        if (data.value) {
          this.selectedRows[key] = this.items[key]
        } else {
          delete this.selectedRows[key]
        }
      }
    }
  },

  watch: {
    allSelected (val) {
      if (val) {
        this.selectedBoxes = new Array(this.items.length).fill(true)
        this.selectedRows = this.items
      } else if (this.selectedBoxes.every((item) => item)) {
        this.selectedBoxes = new Array(this.items.length).fill(false)
        this.selectedRows = []
      }

      this.$emit('selectRow', this.selectedBoxes)
    },
    selectedBoxes (val) {
      if (val.length && val.every((item) => item)) {
        this.allSelected = true
      } else if (this.allSelected) {
        this.allSelected = false
      }

      this.$emit('selectRow', this.selectedRows.filter(item => item !== undefined))
    },
    items (val) {
      this.selectedBoxes = new Array(this.items.length).fill(false)
      this.allSelected = false
      this.selectedRows = []
    }
  }
}
</script>
