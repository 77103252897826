<template>
  <div>
    <VButton
      :text="!isMobile ? 'Журнал изменений' : ''"
      icon="changelog"
      size="sm"
      @click.native="openModalAndFetchData"
    />

    <BaseModal
      v-if="isModal === 'all'"
      title="Журнал изменений"
      class="modal--title-reset modal-changelog"
      @close="closeModal"
    >
      <Spinner v-if="$store.state.isSendingRequestModal" />

      <AppTable
        v-else
        class="modal__table modal__box custom-scroll"
        :columns="allTH"
        :items="all"
        rowActive
        :isChangelog="true"
        @rowClick="showDetails"
      />
    </BaseModal>

    <BaseModal
      v-if="isModal === 'details'"
      title="Изменения"
      class="modal-changelog"
      @close="closeDetail"
    >
      <AppTable
        class="modal__table modal__box custom-scroll"
        :columns="detailsTH"
        :items="details"
      />
    </BaseModal>
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import AppTable from '@/components/ui/AppTable.vue'
import BaseModal from '@/components/ui/Modal/BaseModal.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { MODAL_ALL_TH, MODAL_TH } from '../../model/const/table'
import { ChangelogApi } from '@/api/changelog/ChangelogApi'
import { mapData, mapItemToDetails } from '../../model/map'
import { setAlertError } from '@/utils/store/alert'
import { mapMutations, mapState } from 'vuex'

export default {
  components: { BaseModal, AppTable, VButton },
  mixins: [mixinModal],

  props: {
    subjectId: {
      type: Number,
      required: true
    },
    subjectType: {
      type: String,
      required: true
    }
  },

  data: () => ({
    all: [],
    details: []
  }),

  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),
    allTH: () => MODAL_ALL_TH,
    detailsTH: () => MODAL_TH
  },

  methods: {
    ...mapMutations('scroll', {
      setScrollNodeId: 'SET_NODE_ID'
    }),

    async openModalAndFetchData () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')
        this.openModal('all')
        await this.fetchData()
      } catch (e) {
        await setAlertError(e, 'Не удалось загрузить журнал изменений')
        this.closeModal()
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    async fetchData () {
      const response = await ChangelogApi.getAll({
        subject_id: this.subjectId,
        subject_type: this.subjectType,
        sort: '-id'
      })

      this.all = response.data.map(mapData)
    },

    showDetails (item) {
      this.details = mapItemToDetails(item)
      this.setScrollNodeId(`item-changelog--${item.id}`)
      this.openModal('details')
    },

    closeDetail () {
      this.openModal('all')
      this.$store.dispatch('scroll/scrollToNode')
    }
  }
}
</script>
